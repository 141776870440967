'use strict'
angular.module('cb.letterGeneration-Service', [])
	.factory('LetterGenerationService', ["ApiService", function (ApiService) {
		var LetterGenerationService = {

			MakeLetter: function (data, file) {
				try {
					var url = '/webapi/api/v1/letter/GenerateLetter';
					return ApiService.promiseUpload(url, data, file);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			GetLetterTemplates: function () {
				try {
					var url = '/webapi/api/v1/letter/template/getlettertemplates';
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			emailContract: function (data) {
				//alert(0);
				try {
					var url = '/webapi/api/v1/letter/emailContract';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			emailDraftContract: function (data) {
				try {
					var url = '/webapi/api/v1/letter/emailDraftContract';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			previewContract: function (data) {
				try {
					var url = '/webapi/api/v1/letter/previewContract';
					return ApiService.promisePost(url, data, { responseType: 'arraybuffer' });
				} catch (e) {
					console.error('Error!', e);
				}
			},
			previewDraftContract: function (data) {
				try {
					var url = '/webapi/api/v1/letter/previewDraftContract';
					return ApiService.promisePost(url, data, { responseType: 'arraybuffer' });
				} catch (e) {
					console.error('Error!', e);
				}
			},
			emailQuote: function (data) {
				try {
					var url = '/webapi/api/v1/letter/emailQuote';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			previewQuote: function (data) {
				try {
					var url = '/webapi/api/v1/letter/previewQuote';
					return ApiService.promisePost(url, data, { responseType: 'arraybuffer' });
				} catch (e) {
					console.error('Error!', e);
				}
			},
			GetLetterTemplate: function (Id) {
				try {
					var url = '/webapi/api/v1/letter/template/getlettertemplate/'+ Id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			NewLetterTemplate: function (Data) {
				try {
					var url = '/webapi/api/v1/letter/template/NewLetterTemplate';
					return ApiService.promisePost(url, Data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			NewLetterTemplateMapping: function (Data) {
				try {
					var url = '/webapi/api/v1/letter/template/NewLetterTemplateMapping';
					return ApiService.promisePost(url, Data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			UpdateLetterTemplate: function (Data) {
				try {
					var url = '/webapi/api/v1/letter/template/UpdateLetterTemplate';
					return ApiService.promisePost(url, Data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			UpdateLetterTemplateMapping: function (Data) {
				try {
					var url = '/webapi/api/v1/letter/template/UpdateLetterTemplateMapping';
					return ApiService.promisePost(url, Data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
		}
		return LetterGenerationService;
	}])
